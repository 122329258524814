import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PageTitle from '../components/Blocks/PageTitle'
import Faq from '../components/Faq'

const BuyingTemplate = ({ title, content, page: { buyingFields }, faq }) => (
  <div className="w-full h-full">
    <PageTitle title={title} />
    {buyingFields && (
      <div className="flex flex-wrap-reverse h-full items-stretch buying-items">
        <div
          className="bg-brand-green md:w-1/2 w-full relative px-8 py-10 md:px-20 md:py-20 text-black flex flex-col justify-center"
          style={{ minHeight: '300px' }}
        >
          <h2 className="text-black">{buyingFields.buyingTitle}</h2>
          <h3>{buyingFields.subtitle}</h3>
          <div
            className="transition-all text-lg my-8"
            dangerouslySetInnerHTML={{ __html: buyingFields.content }}
          />
          <div>
            <a
              href="#quoteForm"
              class="btn text-black border border-black mt-8"
            >
              {buyingFields.buttonText}
            </a>
          </div>

          <div className="absolute -top-6 right-0 md:top-0 w-full h-6 md:w-6 md:h-full md:-right-6 bg-brand-green opacity-70" />
          <div className="w-full h-6 md:w-6 md:h-full absolute right-0 -top-12 md:-right-12 md:top-0 bg-brand-green opacity-30" />
        </div>
        <img
          className="object-cover md:w-1/2 w-full"
          src={buyingFields?.image.mediaItemUrl}
          style={{
            minHeight: '300px',
          }}
        />
      </div>
    )}

    <div className="max-w-screen-xl mx-auto">
      <div
        className="transition-all"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <Faq title={faq?.title} items={faq?.faqs} />
    </div>
  </div>
)

const Page = ({ data, pageContext }) => {
  const { wpPage: page } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <Helmet>
        <script type="application/ld+json">
          {`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"What is an off-lease car?","acceptedAnswer":{"@type":"Answer","text":"An off-lease car is a vehicle that’s been returned to a dealer at the end of its lease. Generally, off-lease cars have been gently used and well cared for. At D&amp;M, we hundreds of well maintained lease returns for you to select from."}},{"@type":"Question","name":"Where to buy off-lease cars?","acceptedAnswer":{"@type":"Answer","text":"Most used cars available for sale at various dealerships are acquired at auctions and then remarketed retail to the public. D&amp;M’s inventory is unique in that the never ending supply of vehicles are directly off lease from our thousands of active leases terminating daily and are not acquired at auctions.Our subsidiary Dallas Lease Returns offers the best pre-owned car inventory at the lowest prices. Dallas Lease Returns carries over 400 unique Lease Returns and trades per month. Primarily these vehicles are one owner, local lease returns. Check out our online inventory today!"}},{"@type":"Question","name":"Are lease returns a good buy?","acceptedAnswer":{"@type":"Answer","text":"Yes! If you are looking to buy a gently pre-owned car, buying a lease return could be right for you. It is well documented that lease vehicles are maintained with services up to date and offer good value on the used car market."}}]}`}
        </script>
      </Helmet>
      <BuyingTemplate
        page={page}
        title={page.title}
        content={page.content}
        faq={page.faq}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query BuyingPageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      faq {
        title
        faqs {
          body
          title
        }
      }
      seo {
        fullHead
        schema {
          raw
        }
      }
      buyingFields {
        buyingTitle
        content
        subtitle
        buttonText
        image {
          id
          mediaItemUrl
        }
      }
    }
  }
`
